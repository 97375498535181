import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { fetchAll } from '../../services/chat-queries';
import { QueryGroup } from '../../types';

const KEY = 'queries';

export function useQueryGroups({
  params,
  options = {},
}: {
  params: Parameters<typeof fetchAll>[0];
  options?: Omit<UseQueryOptions<QueryGroup[]>, 'queryKey' | 'queryFn'>;
}) {
  return useQuery<QueryGroup[]>({
    queryKey: [KEY, params],
    queryFn: () => fetchAll(params),
    ...options,
  });
}
