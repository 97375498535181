import { QueryGroup } from '../../types';
import { api } from '../../utils/api';

export async function fetchAll(params: {
  orgId: string;
  pid: string;
}): Promise<QueryGroup[]> {
  return api
    .get<QueryGroup[]>(`/orgs/${params.orgId}/${params.pid}/query-groups`, {
      params,
    })
    .then(({ data }) => data);
}
